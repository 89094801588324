/* 
 * Typography
 */
 
 @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

 $font-family-base:    'Montserrat', sans-serif;
 $font-family-heading: 'Montserrat', sans-serif;

$font-weight-light:   300;
$font-weight-regular: 400;
$font-weight-medium:  500;
$font-weight-bold:    600;
$font-weight-black:   900;

$font-size-min:       14px;
$font-size-max:       16px;
$font-size-base:      1rem;
$font-size-lg:        1.25rem;
$font-size-sm:        0.875rem;
$font-size-xs:        0.75rem;

$line-height:         1.5;