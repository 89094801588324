/*
 * Author: Niels Buijtenhuis
 *
 * SCSS structure:
 * 0 - Vendor — Optional third party imports
 * 1 - Settings — preprocessors, fonts, variables
 * 2 - Tools — mixins, functions
 * 3 - Base — reset / normalize
 * 4 - Elements — HTML elements 
 * 5 - Components — UI components
 * 6 - Helpers - helper classes
 */

// VENDOR
@import "normalize-scss/sass/normalize";

// SETTINGS
@import "1 - settings/colors";
@import "1 - settings/fonts";
@import "1 - settings/breakpoints";
@import "1 - settings/inputs";
@import "1 - settings/button";

// TOOLS
@import "2 - tools/mixins";
@import "2 - tools/media-queries";
@import "2 - tools/grid";

// BASE
@import "3 - base/reboot";
@import "3 - base/layout";

// ELEMENTS
@import "4 - elements/forms";
@import "4 - elements/headings";
@import "4 - elements/media";
@import "4 - elements/buttons";

// COMPONENTS
@import "5 - components/full-video";
@import "5 - components/header";
@import "5 - components/navigation";
@import "5 - components/portfolio";
@import "5 - components/item";

// HELPERS
@import "6 - helpers/helpers";