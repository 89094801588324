/* 
 * Form fields
 */

// Textfields
input {
    &[type=date],
    &[type=text],
    &[type=email],
    &[type=url],
    &[type=search],
    &[type=password],
    &[type=tel] {
      @extend %input-text;
    }
  }
  
  //Textarea
  textarea {
    @extend %input-text;
  }

  select {
    @extend %input-select;
  }