.beeldmerk {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 1000;
    max-width: 50px;
    
    @include respond-below(md){
        top: 20px;
        left: 20px;
    }
}

#navigation {
    position: fixed;
    right: 40px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:20000;
    
    ul {
        @extend .reset;
        display: none;

        &.active {
            display: block;
        }

        li {
            margin: 10px 5px;
            display: block;

            a {
                width: 45px;
                height: 45px;
                padding: 3px;
                // border: 1px solid $white;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                position: relative;

                &::before,
                &::after {
                    box-sizing: inherit;
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    color: #a00;
                }
                
                &::before,
                &::after {
                    top: 0;
                    left: 0;
                }
                
                &::before {
                    border: 2px solid transparent; // We're animating border-color again
                }
                
                &:hover::before {
                    border-top-color: #a00; // Show borders
                    border-right-color: #a00;
                    border-bottom-color: #a00;
                
                    transition:
                    border-top-color 0.15s linear, // Stagger border appearances
                    border-right-color 0.15s linear 0.10s,
                    border-bottom-color 0.15s linear 0.20s;
                }
                
                &::after {
                    border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
                }
                
                &:hover::after {
                    border-top: 2px solid #a00; // Shows border
                    border-left-width: 2px; // Solid edges, invisible borders
                    border-right-width: 2px; // Solid edges, invisible borders
                    transform: rotate(270deg); // Rotate around circle
                    transition:
                    transform 0.4s linear 0s,
                    border-left-width 0s linear 0.35s; // Solid edge post-rotation
                }

                &::before,
                &::after {
                border-radius: 100%;
                }

                img {
                    padding: 7px;
                    max-width: 100%;
                }
            }
        }
    }

    @include respond-below(md){
        right: 10px;
    }
}