#begin {
    background: $white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;

    &.remove {
        opacity: 0;
    }
}
#header {
    width: 100vw;
    height: 100vh;
    position: relative;

    &::after {
        position: absolute;
        z-index:100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 0.6) calc(100% - 400px), rgba($white, 0.8) 100%);
    }

    ul {
        @extend .reset;

        li {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-size: cover;
            background-position: center center;
            transition: opacity 1200ms;

            &.active {
                opacity: 1;
            }
        }
    }
}


.text {
    position: absolute;
    top: 50%;
    right: 140px;
    transform: translateY(-50%);
    color: $black;
    max-width: 380px;
    z-index: 100;

    h1 {
        font-size: 36px;
        line-height: 1;
        color: $black;
        // text-shadow: 2px 2px 2px rgba(#000000, 0.5);
        margin: 0 0 10px 0;

        span {
            font-size: 120px;
            width: 100%;
            display:block;
        }
    }

    p {
        // text-shadow: 2px 2px 2px #000000;
        line-height: 1.4;
        color: $black;
        font-size: 1rem;
        text-align: justify;

        a {
            color: $white;
        }
    }

    &.ijs {
        right:180px;
        max-width: 440px;

        h1 {
            font-size: 32px;

            span {
                font-size: 101px;
            }
        }
    }

    @include respond-below(md) {
        max-width: 250px;
        left: 20px;
        right: auto;

        h1 {
            font-size: 1rem;

            span {
                font-size: 1rem;
                display: inline-block;
                width: auto;
            }
        }
    }
}