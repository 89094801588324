@charset "UTF-8";
/*
 * Author: Niels Buijtenhuis
 *
 * SCSS structure:
 * 0 - Vendor — Optional third party imports
 * 1 - Settings — preprocessors, fonts, variables
 * 2 - Tools — mixins, functions
 * 3 - Base — reset / normalize
 * 4 - Elements — HTML elements 
 * 5 - Components — UI components
 * 6 - Helpers - helper classes
 */
/* 
 * All colors used on the website.
 */
/* 
 * Typography
 */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");
/*
 * Grid, Breakpoints & Widths
 */
/* 
 * Form field base
 */
input[type=date], input[type=text], input[type=email], input[type=url], input[type=search], input[type=password], input[type=tel], textarea {
  width: 100%;
  max-width: 400px;
  padding: .4em .4em;
  border: 1px solid #787878;
  background: #fff;
  color: #787878;
  outline: 0;
  border-radius: 5px;
  font-size: .9rem; }
  input:focus[type=date], input:focus[type=text], input:focus[type=email], input:focus[type=url], input:focus[type=search], input:focus[type=password], input:focus[type=tel], textarea:focus {
    border-color: #000000;
    background: #fff; }

select {
  width: 100%;
  max-width: 400px;
  background: #fff url("../images/down-arrow.svg") no-repeat right 15px center;
  background-size: 15px 15px;
  appearance: none;
  border: 2px solid #000000;
  outline: 0;
  padding: .8em 1em;
  line-height: normal;
  border-radius: 5px;
  font-size: 1.125rem; }
  select:focus {
    background: #fff url(../images/up-arrow.svg);
    border-color: #000000; }

/* 
 * Button base
 */
/* 
 * Mixins
 */
/* 
 * Mixin for Media Queries
 */
/* 
 * Grid mixin
 */
/* 
 * Reset HTML elements and layout
 */
::selection {
  color: #fff;
  background: #000000; }

*,
input[type="search"] {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url("../images/cursor.png"), auto; }

html {
  font-size: 14px; }
  @media (min-width: 768px) {
    html {
      font-size: 15px; } }
  @media (min-width: 992px) {
    html {
      font-size: 16px; } }

body {
  background: #fff;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0; }

a {
  color: #000000;
  transition: color 0.3s ease-in-out; }
  a:hover {
    color: #252932;
    text-decoration: none; }

li {
  font-size: 1.25rem;
  line-height: 1.8; }

p {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 1.25rem;
  line-height: 1.8; }
  p:last-child {
    margin-bottom: 0; }

b,
strong {
  font-weight: 600; }

embed,
img,
object,
video {
  display: block;
  max-width: 100%;
  height: auto; }

/* 
 * Layout base
 */
body {
  width: 100%;
  overflow-x: hidden; }

.layout-container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  margin: 0;
  transition: transform 400ms; }
  @media (max-width: 991px) {
    .layout-container.slide-canvas {
      position: fixed;
      transform: translate(400px); } }
  @media (max-width: 767px) {
    .layout-container.slide-canvas {
      transform: translate(calc(100% - 60px)); } }

section {
  position: relative;
  padding: 40px 0; }

.wrapper {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  position: relative;
  display: block; }
  @media (max-width: 1279px) {
    .wrapper {
      padding: 0 40px; } }
  @media (max-width: 767px) {
    .wrapper {
      padding: 0 20px; } }

/* 
 * Form fields
 */
/* 
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  color: #000000; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.25rem; }

/* 
 * Media
 */
/* 
 * Buttons
 */
.button {
  display: inline-block;
  vertical-align: middle;
  padding: .7em 1.7em;
  border: 2px solid transparent;
  -webkit-appearance: none;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
  transition-property: background-color, border-color, color;
  transition-duration: 0.3s;
  outline: 0;
  text-align: center;
  min-width: auto;
  white-space: nowrap;
  text-decoration: none !important;
  line-height: initial;
  position: relative;
  background-color: #000000;
  border-color: #000000;
  color: #fff;
  transition: all 0.3s ease-in-out; }
  .button:hover, .button:focus {
    color: #fff;
    background-color: #5D5E5E;
    border-color: #5D5E5E; }
  .button:focus {
    background-color: #444444;
    border-color: #444444; }

/*
 * (Responsive) Full video container
 */
.full-video {
  height: 100vh; }
  .full-video .video-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
    min-width: 100vw;
    height: 100vh; }
    .full-video .video-container video {
      position: absolute;
      width: auto;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      transform: translate(-50%, -50%); }
      @media (max-width: 575px) {
        .full-video .video-container video {
          max-height: 100%; } }
    .full-video .video-container::after {
      content: '';
      position: absolute;
      background: rgba(37, 41, 50, 0.6);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0; }

#begin {
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center; }
  #begin.remove {
    opacity: 0; }

#header {
  width: 100vw;
  height: 100vh;
  position: relative; }
  #header::after {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) calc(100% - 400px), rgba(255, 255, 255, 0.8) 100%); }
  #header ul li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-size: cover;
    background-position: center center;
    transition: opacity 1200ms; }
    #header ul li.active {
      opacity: 1; }

.text {
  position: absolute;
  top: 50%;
  right: 140px;
  transform: translateY(-50%);
  color: #000000;
  max-width: 380px;
  z-index: 100; }
  .text h1 {
    font-size: 36px;
    line-height: 1;
    color: #000000;
    margin: 0 0 10px 0; }
    .text h1 span {
      font-size: 120px;
      width: 100%;
      display: block; }
  .text p {
    line-height: 1.4;
    color: #000000;
    font-size: 1rem;
    text-align: justify; }
    .text p a {
      color: #fff; }
  .text.ijs {
    right: 180px;
    max-width: 440px; }
    .text.ijs h1 {
      font-size: 32px; }
      .text.ijs h1 span {
        font-size: 101px; }
  @media (max-width: 767px) {
    .text {
      max-width: 250px;
      left: 20px;
      right: auto; }
      .text h1 {
        font-size: 1rem; }
        .text h1 span {
          font-size: 1rem;
          display: inline-block;
          width: auto; } }

.beeldmerk {
  position: fixed;
  top: 40px;
  left: 40px;
  z-index: 1000;
  max-width: 50px; }
  @media (max-width: 767px) {
    .beeldmerk {
      top: 20px;
      left: 20px; } }

#navigation {
  position: fixed;
  right: 40px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000; }
  #navigation ul {
    display: none; }
    #navigation ul.active {
      display: block; }
    #navigation ul li {
      margin: 10px 5px;
      display: block; }
      #navigation ul li a {
        width: 45px;
        height: 45px;
        padding: 3px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative; }
        #navigation ul li a::before, #navigation ul li a::after {
          box-sizing: inherit;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%; }
        #navigation ul li a:hover {
          color: #a00; }
        #navigation ul li a::before, #navigation ul li a::after {
          top: 0;
          left: 0; }
        #navigation ul li a::before {
          border: 2px solid transparent; }
        #navigation ul li a:hover::before {
          border-top-color: #a00;
          border-right-color: #a00;
          border-bottom-color: #a00;
          transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s; }
        #navigation ul li a::after {
          border: 0 solid transparent; }
        #navigation ul li a:hover::after {
          border-top: 2px solid #a00;
          border-left-width: 2px;
          border-right-width: 2px;
          transform: rotate(270deg);
          transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s; }
        #navigation ul li a::before, #navigation ul li a::after {
          border-radius: 100%; }
        #navigation ul li a img {
          padding: 7px;
          max-width: 100%; }
  @media (max-width: 767px) {
    #navigation {
      right: 10px; } }

#portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; }
  #portfolio .holder {
    width: 100%;
    max-width: 1260px;
    padding: 0 80px; }
    #portfolio .holder ul li {
      display: inline-block; }
      #portfolio .holder ul li a {
        color: #000000;
        font-size: 2rem;
        text-decoration: none;
        font-weight: bold;
        border-right: 1px solid #000000;
        padding: 0 10px; }
        #portfolio .holder ul li a:hover {
          color: #787878; }
    @media (max-width: 767px) {
      #portfolio .holder {
        padding: 0 20px; }
        #portfolio .holder ul {
          padding-right: 40px !important; }
          #portfolio .holder ul li a {
            padding-left: 0;
            font-size: 1rem; } }

#item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh; }
  #item .holders {
    padding: 0 120px;
    overflow: hidden; }
    #item .holders .holder {
      display: flex;
      width: 100%;
      max-width: 1260px;
      height: 400px;
      overflow-x: auto;
      flex-wrap: nowrap; }
      #item .holders .holder .flex {
        height: 100%;
        display: flex;
        flex: 0 0 auto;
        overflow: auto;
        width: auto; }
        #item .holders .holder .flex img {
          max-height: 100%; }
    @media (max-width: 767px) {
      #item .holders {
        padding: 0 70px 0 20px; } }
  #item .control {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 20px; }
    #item .control a {
      text-decoration: none;
      font-weight: bold; }

/*
 * Helper classes
 */
.box-shadow {
  box-shadow: 0px 5px 8px 1px #d8d8d8; }

.text-shadow {
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); }

.centered {
  margin: 0 auto;
  text-align: center;
  display: block; }

.centered-vertical {
  display: flex !important;
  align-items: center;
  justify-content: center; }

.background-image {
  background-size: cover;
  background-position: center; }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1; }

.reset, #header ul, #navigation ul, #portfolio .holder ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.embed {
  position: relative;
  overflow: hidden;
  padding-top: -56.25%; }
  .embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
