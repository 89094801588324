#item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    .holders {
        padding: 0 120px;
        overflow: hidden;

        .holder {
            display: flex;
            width: 100%;
            max-width: 1260px;
            height: 400px;
            overflow-x: auto;
            flex-wrap: nowrap;

            .flex {
                height: 100%;
                display: flex;
                flex: 0 0 auto;
                overflow: auto;
                width: auto;

                img {
                    max-height: 100%;
                }
            }
        }

        @include respond-below(md){
            padding: 0 70px 0 20px;
        }
    }

    .control {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 20px;

        a {
            text-decoration: none;
            font-weight: bold;
        }
    }
}