#portfolio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .holder {
        width: 100%;
        max-width: 1260px;
        padding: 0 80px;

        ul {
            @extend .reset;

            li {
                display: inline-block;

                a {
                    color: $black;
                    font-size: 2rem;
                    text-decoration: none;
                    font-weight: bold;
                    border-right: 1px solid $black;
                    padding: 0 10px;

                    &:hover {
                        color: $grey-light;
                    }
                }
            }
        }

        @include respond-below(md){
            padding: 0 20px;

            ul {
                padding-right: 40px !important;

                li {
                    a {
                        padding-left: 0;
                        font-size: 1rem;
                    }
                }
            }
        }
    }   
}