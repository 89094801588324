/*
 * (Responsive) Full video container
 */

 .full-video{
    height: 100vh;

    .video-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: 0;
        min-width: 100vw;
        height: 100vh;

        video {
            position: absolute;
            width: auto;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            max-width: none;
            transform: translate(-50%, -50%);

            @include respond-below(sm){
                max-height: 100%;
            }
        }

        &::after {
            content: '';
            position: absolute;
            background: rgba($grey-dark, 0.6);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
}