/* 
 * All colors used on the website.
 */

$white:               #fff;
$black:               #000000;
$off-white:           #f8f8f8;

$grey-darkest:        #111111;
$grey-darker:         #1B1E24;
$grey-dark:           #252932;
$grey:                #5D5E5E;
$grey-light:          #787878;
$grey-lighter:        #D8D8D8;
$grey-lightest:       #F7F7F7;

$succes:              #5cb85c;
$warning:             #f0ad4e;
$error:               #d9534f;

// Assign colors

$body-bg:             $white;
$body-color:          $black;
$selection-bg:        $black;
$selection-color:     $white;
$link-color:          $black;
$link-hover-color:    $grey-dark;

$primary-color:       $black;
$secondary-color:     $grey;

$button-bg:           $primary-color;
$button-hover-bg:     $secondary-color;
$button-color:        $white;
